const accordionWrapper = document.querySelector('[data-accordions]');

if ( accordionWrapper ) {
    const accordions = document.querySelectorAll('.accordion-panel');

    const accButtons = document.querySelectorAll('.accordion-trigger')
    const accItems = document.querySelectorAll('.item-content')

    accButtons.forEach((trigger, index)=>{
        trigger.addEventListener('click', ()=>{
            if (trigger.getAttribute('aria-expanded') ==='true'){
                trigger.setAttribute('aria-expanded', 'false')
                accItems[index].dataset.show = 'false'
            }else{
                accItems[index].dataset.show = 'true'
                trigger.setAttribute('aria-expanded', 'true');

                accButtons.forEach((triggerJ, index)=>{
                    if (trigger !== triggerJ){
                        accItems[index].dataset.show = 'false'
                        triggerJ.setAttribute('aria-expanded', 'false');
                    }
                })
            }
        })
    })
}