

const filtersWrapper = document.getElementById('filters-wrapper');

if(filtersWrapper){
    const filterForm = document.querySelector('#filters-wrapper form');
    const filterBox = document.querySelectorAll('#filters-wrapper .filter-box.filter-mobile-swap');
    const filterBoxDropdowns = document.querySelectorAll('#filters-wrapper .filter-box .filter-drop');

    const isAjaxFilter = document.querySelector('#filters-wrapper.ajax-filter');


    const itemsToFilter = document.querySelectorAll('[data-item]');

    if (filterForm){
        filterForm.addEventListener('submit', (e)=>{
            e.preventDefault()
        });
    }

    let filters = {}

    filterBox.forEach((box, index) =>{
        // create arrays from filter boxes
        const drop =  box.querySelector('.filter-dropdown-menu')
        const filterItem = drop.dataset.filterBy;
        filters[filterItem] = []
    })



    filterBox.forEach(filter=>{
        const filterDropdownTrigger = filter.querySelector('.filter-drop');
        const dynamicText = filter.querySelector('.dyn-text');

        const clearButton = filter.querySelector('.filter-dropdown-menu .clear');

        const checkboxes = filter.querySelectorAll('.filter-dropdown-menu li .checkbox input');


        const filtersDropdownMenu = filter.querySelector('.filter-dropdown-menu');
        const filterTopic = filtersDropdownMenu.dataset.filterBy;



        filterDropdownTrigger.addEventListener('click', ()=>{

            filterBoxDropdowns.forEach(dropdown=>{
                const parent = dropdown.closest('.filter-box');

                if (parent.classList.contains('drop-open')){
                    parent.classList.remove('drop-open');
                    return
                }

                if (dropdown === filterDropdownTrigger){
                    parent.classList.add('drop-open');
                }else{
                    parent.classList.remove('drop-open');
                }
            })
        });

        if (clearButton){
            clearButton.addEventListener('click', ()=>{
                clearFilter(filterTopic, dynamicText, clearButton, checkboxes);
                updateUrl()
            })
        }

        
        checkboxes.forEach(checkbox =>{
            checkbox.addEventListener('change', ()=>{

                if (checkbox.type === 'radio'){
                    if (checkbox.checked){
                        const checkboxTitle = checkbox.dataset.title;


                        // creates object that add to filter[filterTopic] array
                        const selectedItem = {
                            value: checkbox.value,
                            title: checkboxTitle
                        }

                        filters[filterTopic] = []
                        filters[filterTopic].push(selectedItem)

                    }
                }else{
                    if (checkbox.checked) {
                        const checkboxTitle = checkbox.dataset.title;

                        // creates object that add to filter[filterTopic] array
                        const selectedItem = {
                            value: checkbox.value,
                            title: checkboxTitle
                        }

                        filters[filterTopic].push(selectedItem)

                    }else{
                        // removes item object from filter[filterTopic] array
                        const index = filters[filterTopic].findIndex(item=> item.value === checkbox.value);
                        filters[filterTopic].splice(index,1)
                    }
                }


                toggleClearButton(filterTopic, clearButton);
                toggleDynamicText(filterTopic, dynamicText);

                updateUrl();

                if (isAjaxFilter) {
                    filterNews();
                }else{
                    filterEvents()
                }

            })
        });




        
        function clearFilter(topic, dynamicText, clearButton, checkboxes ){
            filters[topic] = [];
            toggleDynamicText(topic, dynamicText);
            uncheckCheckboxes(checkboxes);
            toggleClearButton(topic, clearButton);
            if (isAjaxFilter) {
                filterNews();
            }else{
                filterEvents()
            }
        }

        function uncheckCheckboxes(){
            checkboxes.forEach(item=>{
                item.checked = false
            })
        }

        document.addEventListener('click', function(event) {
                if (!event.target.closest(".filter-box")) {
                    filterBox.forEach(box=>{
                        box.classList.remove('drop-open')
                    })
                }
        }, false)

    })

    function toggleDynamicText(topic, dynamicText){
        dynamicText.innerHTML = ''

        if (filters[topic].length >= 1){

            filters[topic].forEach((item, index)=>{
                let divider = '';
                if(index > 0) {
                    divider = ', ';
                }
                dynamicText.innerHTML += `${divider}${item.title }`;
            })
        }else{
            dynamicText.innerText = dynamicText.dataset.default
        }

        if (filters[topic].length >= 2) {
            dynamicText.classList.add('show-nr')
            dynamicText.dataset.value = filters[topic].length;
        }else{
            dynamicText.classList.remove('show-nr')
            dynamicText.removeAttribute('data-value');
        }
    }

    function toggleClearButton(topic, clearButton){
        if(!clearButton){
            return
        }
        if (filters[topic].length >= 1) {
            clearButton.style.display = 'block';
        }else{
            clearButton.style.display = 'none';
        }
    }


    const noFiltersFound = document.getElementById('training-no-found')
    function filterEvents(){
        let eventsExists = false;
        itemsToFilter.forEach(item=>{
            let isVisible = true;
            const includesAny = (arr, values) => values.some(v => arr.includes(v));

            // Loop filters object by specific filters
            Object.keys(filters).forEach(filterKey=>{

                // Gives one filterTopic array Example "event topics"
                const filterKeyValues = filters[filterKey];
                // gets value of array value because every checkbox has title and value
                const mappedFilterValues = filterKeyValues.map(obj => obj.value);

                // get event data attributes by filter key
                const eventFilterData = item.dataset[filterKey];
                // creates array from filter data attributes
                const eventValues = eventFilterData ? eventFilterData.split(' ') : [];

                // compares if item data attributes matching with filtering items if yes returns true
                const filterMatch =  mappedFilterValues.length === 0 ? true : includesAny(eventValues, mappedFilterValues);

                if (!filterMatch) {
                    isVisible = false;
                }
            })

            if (isVisible) {
                item.classList.remove('hidden');
                eventsExists = true;
            } else {
                item.classList.add('hidden');
            }

            // console.log(eventsExists)

        });

        // TODO: enable this
        if (noFiltersFound){
            if (eventsExists){
                noFiltersFound.style.display = 'none';
            }else{
                noFiltersFound.style.display = 'block';
            }
        }

    }

    const datesLists = document.querySelector('#filters-wrapper .dates-li');


    //DATES lists tabs for EVENT page filters
    if (datesLists) {
        const tabsTriggers = datesLists.querySelectorAll('.nav-tabs li button')
        const tabsContent = datesLists.querySelectorAll('.tab-pane')

        tabsTriggers.forEach(tabButton =>{
            tabButton.addEventListener('click', ()=>{
                const buttonId = tabButton.id;
                toggleDateTabs(buttonId)
            })
        })

        function toggleDateTabs(buttonId){

            tabsTriggers.forEach(tabTrigger=>{
                if (tabTrigger.id === buttonId) {
                    tabTrigger.classList.add('active')
                }else{
                    tabTrigger.classList.remove('active')
                }
            });

            tabsContent.forEach(tabContent=>{
                if (tabContent.id === buttonId) {
                    tabContent.classList.add('active')
                }else{
                    tabContent.classList.remove('active')
                }
            });
        }
    }


    const filterMatchMedia = window.matchMedia('screen and (max-width:'+1140+'px)')
    const mobileFiltersBox = document.getElementById('mobile-filter-box')
    const desktopFiltersBox = document.getElementById('desktop-filter-box')
    function handleMediaQueryChange(mediaQuery) {
        if (mediaQuery.matches) {
            filterBox.forEach(box=>{
                mobileFiltersBox.appendChild(box)
            })
        } else {
            filterBox.forEach(box=>{
                desktopFiltersBox.appendChild(box)
            })
        }
    }

    handleMediaQueryChange(filterMatchMedia);

    window.addEventListener('resize', ()=>{
        handleMediaQueryChange(filterMatchMedia);
    });


    const mobileFilterActionButton = document.querySelectorAll('.mobile-action');

    if (mobileFilterActionButton.length > 0) {
        const body = document.body;

        mobileFilterActionButton.forEach(button=>{
            const target = button.dataset.target;
            const bodyClass = button.dataset.class;
            const action = button.dataset.action;
            const targetElement = document.querySelector(target);


            button.addEventListener('click', ()=>{
                if(!target && !bodyClass && !action) {
                    return;
                }

                if(action == 'toggle') {
                    body.classList.toggle(bodyClass);
                    // $(target).toggleClass('active');
                }
                if(action == 'remove') {
                    body.classList.remove(bodyClass);
                    // $(target).removeClass('active');
                }

            })
        })
    }


    //NEWS filter

    const blogArticlesWrapper = document.getElementById('blogArticlesWrapper');
    const loadMoreNewsButton = document.getElementById('loadMoreNews');

    const ajax_url = ajax_object.ajax_url;
    let newsOffset = 12;
    let buttonLoading = false;

    if (loadMoreNewsButton) {
        loadMoreNewsButton.addEventListener('click', ()=>{

            if (buttonLoading){
                return;
            }

            buttonLoading = true;
            loadMoreNewsButton.classList.add('loading');

            // alert('loading more news')

            let selectedCategories = [];
            Object.keys(filters).forEach(filterKey => {
                const mappedFilterValues = filters[filterKey].map(obj => obj.value);
                selectedCategories = selectedCategories.concat(mappedFilterValues);
            });

            // console.log(selectedCategories)


            const data = new FormData();
            data.append('action', 'filter_news');
            data.append('nonce', ajax_object.nonce);
            data.append('categories', JSON.stringify(selectedCategories));
            data.append('offset', newsOffset);


            fetch(ajax_url, {
                method: "POST",
                // credentials: 'same-origin',
                body: data
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`[${response.status}] ${response.statusText}`);
                    }
                    return response.json();
                })
                .then(response => {
                    // console.log(response);
                    blogArticlesWrapper.innerHTML += response.data.html;
                    newsOffset += 12;

                    // console.log(response.data.remaining)

                    if (response.data.remaining <= 0){
                        loadMoreNewsButton.style.display = 'none';
                    }else{
                        loadMoreNewsButton.style.display = 'grid';
                    }

                    // isLoading = false;
                    buttonLoading = false;
                    loadMoreNewsButton.classList.remove('loading');

                })
                .catch(error => {
                    console.error(error);
                });
        });
    }

    function filterNews(){

        let selectedCategories = [];
        Object.keys(filters).forEach(filterKey => {
            const mappedFilterValues = filters[filterKey].map(obj => obj.value);
            selectedCategories = selectedCategories.concat(mappedFilterValues);
        });

        // console.log(selectedCategories)


        const data = new FormData();
        data.append('action', 'filter_news');
        data.append('nonce', ajax_object.nonce);
        data.append('categories', JSON.stringify(selectedCategories));


        fetch(ajax_url, {
            method: "POST",
            // credentials: 'same-origin',
            body: data
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`[${response.status}] ${response.statusText}`);
            }
            return response.json();
        })
        .then(response => {
            console.log(response);
            blogArticlesWrapper.innerHTML = response.data.html;

            //After filter need to reset offset
            newsOffset = 12;

            if (response.data.remaining <= 0){
                loadMoreNewsButton.style.display = 'none';
            }else{
                loadMoreNewsButton.style.display = 'grid';
            }

        })
        .catch(error => {
            console.error(error);
        });
    }


    function updateUrl(){
        let urlParams = new URLSearchParams();

        Object.keys(filters).forEach(filterKey => {
            if (filters[filterKey].length > 0) {
                // Get array of values for this filter
                const values = filters[filterKey].map(item => item.value);
                // Add to URL params
                urlParams.set(filterKey, values.join(','));
            }
        });


        const newUrl = urlParams.toString()
            ? `${window.location.pathname}?${urlParams.toString()}`
            : window.location.pathname;

        window.history.pushState({}, '', newUrl);
    }


    document.addEventListener('DOMContentLoaded', loadFilterFromUrl);

    function loadFilterFromUrl(){
        const urlParams = new URLSearchParams(window.location.search);
        let hasParams = false;

        urlParams.forEach((value, key) => {
            if (filters.hasOwnProperty(key)) {
                hasParams = true;
                // Split comma-separated values
                const values = value.split(',');

                // Find and check corresponding checkboxes
                values.forEach(val => {
                    const checkbox = document.querySelector(`input[value="${val}"]`);
                    if (checkbox) {
                        checkbox.checked = true;
                        // Update filters object
                        const checkboxTitle = checkbox.dataset.title;
                        filters[key].push({
                            value: val,
                            title: checkboxTitle
                        });
                    }
                });

                // Update UI for this filter group
                const filterBox = document.querySelector(`[data-filter-by="${key}"]`).closest('.filter-box');
                const dynamicText = filterBox.querySelector('.dyn-text');
                const clearButton = filterBox.querySelector('.clear');

                toggleDynamicText(key, dynamicText);
                toggleClearButton(key, clearButton);
            }
        });

        if (hasParams && !isAjaxFilter) {
            filterEvents();
        }
    }

}

