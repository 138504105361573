
import Swiper from 'swiper';
import { Navigation, Pagination , A11y, Keyboard, Autoplay } from 'swiper/modules';

window.addEventListener('DOMContentLoaded', ()=>{
    const swiperHero = new Swiper('#heroSlider.swiper', {
        modules: [Pagination, A11y, Keyboard, Autoplay],
        direction: 'horizontal',
        slidesPerView: 1,
        loop: true,
        autoplay:{
            delay: 5000
        },

        keyboard: {
            enabled: true,
        },
        a11y: {
            enabled: true,
            notificationClass: 'swiper-notification',
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide',
            firstSlideMessage: 'This is the first slide',
            lastSlideMessage: 'This is the last slide',
            paginationBulletMessage: 'Go to slide {{index}}',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            bulletClass: 'swiper-pagination-line',
            bulletActiveClass: 'swiper-pagination-line-active',
            renderBullet: function (index, className) {
                return '<span class="' + className + '"></span>';
            },
        },
    });


    const partnersSliderWrapper = document.getElementById('partnersSlider');

    if (partnersSliderWrapper){

        const partnersSliderWrapper = new Swiper('#partnersSlider.swiper',{
            modules: [Pagination, A11y, Keyboard, Autoplay, Navigation],
            direction: 'horizontal',
            slidesPerView: 1,

            keyboard: {
                enabled: true,
            },
            a11y: {
                enabled: true,
                notificationClass: 'swiper-notification',
                prevSlideMessage: 'Previous slide',
                nextSlideMessage: 'Next slide',
                firstSlideMessage: 'This is the first slide',
                lastSlideMessage: 'This is the last slide',
                paginationBulletMessage: 'Go to slide {{index}}',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    }
})

