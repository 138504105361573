import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
document.addEventListener("DOMContentLoaded", ()=>{
    gsap.registerPlugin(ScrollTrigger);


    const heroSection = document.querySelectorAll('.scale-down');

    heroSection.forEach(section=>{
        const TL = gsap.timeline();

        const content = section.querySelector('.content');

        TL.to(section, {scale: 1,  duration: 0.3, delay: 0.1})
        TL.to(content, {opacity: 1, y: 0, duration: 0.3})
    })




    //SIMPLE FADE UP ANIMATION
    const fadeUpSections = document.querySelectorAll('.animated-fade-up');

    if (fadeUpSections.length > 0) {
        fadeUpSections.forEach(section=>{
            const content = section.querySelector('.animated-fade-up-content')
            const tl = gsap.timeline()

            tl.fromTo(section, {y:40, opacity:0}, {y:0, opacity: 1, duration: .4})

            ScrollTrigger.create({
                trigger: section,
                start: 'top 97%',
                animation: tl,
                markers:false
            })
        })
    }


    // ANIMATED FADE UP ON LOAD
    const animatedFadeUpOnLoadItems = document.querySelectorAll('.animated-fade-up-load');

    if (animatedFadeUpOnLoadItems.length > 0){
        animatedFadeUpOnLoadItems.forEach(item=>{
            const TL = gsap.timeline();

            TL.to(item, {opacity: 1, y: 0, duration: 0.4})
        })
    }



    // ANIMATED NUMBERS
    const ease = {
        linear: t => t,
        inOutQuad: t => t<.5 ? 2*t*t : -1+(4-2*t)*t,
    };

    const counter = (EL) => {

        const duration = 3000; // Animate all counters equally for a better UX
        const start = parseInt(EL.textContent, 10); // Get start and end values
        const endValue = EL.dataset.counter;
        const end = parseFloat(endValue.replace(',', '.'));
        const hasDecimals = checkDecimals(end)

        function checkDecimals(num){
            return (num % 1) !== 0;
        }

        if (start === end) return; // If equal values, stop here.

        const range = end - start; // Get the range
        let curr = start; // Set current at start position
        const timeStart = Date.now();

        const loop = () => {
            let elaps = Date.now() - timeStart;
            if (elaps > duration) elaps = duration; // Stop the loop
            const norm = ease.inOutQuad(elaps / duration); // normalised value + easing
            const step = norm * range; // Calculate the value step
            curr = start + step; // Increment or Decrement current value
            if (hasDecimals){
                EL.textContent = curr.toFixed(1).replace('.', ','); // Apply to UI as integer
            }else{
                EL.textContent = Math.trunc(curr); // Apply to UI as integer
            }
            if (elaps < duration) requestAnimationFrame(loop); // Loop
        };

        requestAnimationFrame(loop); // Start the loop!
    };

    const target = document.getElementById('animatedNumbersWrapper');
    if (target){
        const options = {
            rootMargin: '0px',
            threshold: 1.0,
        };

        const intersectionCallback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    document.querySelectorAll("[data-counter]").forEach(counter);
                }
            });
        }

        const observer = new IntersectionObserver(intersectionCallback, options);
        observer.observe(target);
    }

})
