import './sliders';
import './filters';
import './accordion';
import './animations';

import.meta.glob([ '../img/**', ]);

const mobileMenu = document.getElementById('mobileMenu');
const hamburgerMenuButton = document.getElementById('hamburgerMenuButton');
const hamburgerMenuButtonClose = document.getElementById('hamburgerMenuButtonClose');

hamburgerMenuButton.addEventListener('click', ()=>{
    changeMobileMenuStatus('open')
})

hamburgerMenuButtonClose.addEventListener('click', ()=>{
    changeMobileMenuStatus('close')
})

function changeMobileMenuStatus(state){
    if (state === 'open'){
        mobileMenu.dataset.open = 'true';
    }else{
        mobileMenu.dataset.open = 'false';
    }
}


const wordpressVideoBlocks = document.querySelectorAll('.wp-block-video');

if (wordpressVideoBlocks.length > 0) {
    wordpressVideoBlocks.forEach(videoBlock=>{
        const video = videoBlock.querySelector('video');
        const customPlayButton = videoBlock.querySelector('.custom-play-button');

        video.controls = false;

        customPlayButton.addEventListener('click', ()=>{
            video.controls = true;
            customPlayButton.classList.add('hidden');
            video.play();
        });
    })
}


const hiddenContentWraps = document.querySelectorAll('.hidden-content-wrap');

if (hiddenContentWraps.length > 0){
    hiddenContentWraps.forEach(hiddenContentWrap=>{

        const hiddenContent = hiddenContentWrap.querySelector('.hidden-content');
        const toggleButton = hiddenContentWrap.querySelector('.read-more-toggle');

        toggleButton.addEventListener('click', ()=>{
            if (hiddenContent.dataset.show === 'false'){
                hiddenContent.dataset.show = 'true';
                toggleButton.innerText = toggleButton.dataset.less;
            }else{
                hiddenContent.dataset.show = 'false';
                toggleButton.innerText = toggleButton.dataset.more;
            }

        })
    })
}

document.addEventListener('DOMContentLoaded', function() {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    document.documentElement.style.setProperty('--scrollbar-width', scrollbarWidth - 2 + 'px');
});